*{
  padding:0;
  margin:0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sora&display=swap');


@font-face {
  font-family: 'Hyundai Sans Head Office regular';
   src: url('../Hyundai font/HyundaiSansHeadOffice-Regular.ttf') format('truetype');
 
}

@font-face {
  font-family: 'Hyundai Sans Head Office Bold';
   src: url('../Hyundai font/HyundaiSansHeadOffice-Bold.ttf') format('truetype');
 
}


body{
  background-color: #F6F3F2;
  font-family: "Hyundai Sans Head Office regular" !important;
}
.full-page-container{
padding-top: 20px;
padding-bottom: 20px;
}

/* start of navbar css */
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.navbars{
  display:flex;
  /* to go to end....push both the items to both edges*/
  justify-content: space-between;
  background-color:  #FFFFFF;
 
  /* to keep all items in middle of navbar */
  align-items: center;

  text-align: center;
  height:50px;
  position: fixed;
  width:100%;
  /* border-bottom: 1px solid #B8BAB6; */
  box-shadow: 0px 0.3px 0.3px #B8BAB6; 
  


}
.welcome-text {
  font-family: "Hyundai Sans Head Office regular" ;
  /* font-weight: 600; */
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center; /* Vertically center the content */
  /* padding-top: auto; */
}
.threedheading{
  color:#1A1A1A !important;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 700;
  text-align: center;

}
.title-section-threed{
  color:#808080;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;

}
.admin-text{
  color:  #343434;
  font-weight: 400;
  font-size: 14px;
 
}

.logout-btn {
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  border: 1px solid #685290;
  border-radius: 5px;
  height: 30px;
  
  
   font-size: 14px;
  display: flex;
  align-items: center; /* Vertically center the content */
  padding-left: 5px;
  padding-right: 5px;
  background-color: #FFFFFF;

  outline: none;
  color:#685290;
  cursor:pointer;

}

.nav-links{
  /* to display one next to another */
  display:flex;
  gap:20px;
   list-style-type: none;
   padding-right: 20px;
  }
a{
  text-decoration: none;
  cursor:pointer;
   color:white;
   font-size:20px;
}
.mobile-menu-icon{
   width:30px; 
    /* display:block;  */
    display:none;  
  right:5px;
  }
  .logo{
      padding-left: 20px;
  }


 


/* end of naavbar css */
/* start of device status bar css */
.status-tab{
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
}

.tabs-flexbox-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:20px;
  border-bottom: 1px solid #CDCDCD;
  margin-top: 20px;
  /* margin-left: 30px;
  margin-right: 30px; */
  padding-bottom: 0px;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  
 
}
.tabs-date-flexbox-container{
display: flex;
flex-direction: row;
justify-content: space-between;

}
.tabs-flexbox-container-two{
  display:flex;
  flex-direction: row;
  gap:20px;

}

.active-tab{
  cursor: pointer ;
  border-bottom: 2px solid  #003984 !important; 

  padding-bottom: 10px !important;
  color:#003984 !important;
  font-size: 16px;
  font-family:"Hyundai Sans Head Office regular" ;
  font-weight: 600;

}
.inactive-tab{
  cursor: pointer;
  color: #808080;
  font-weight: 400;
  font-size: 16px;
  font-family: "Hyundai Sans Head Office regular" ;


}
.download-img{
  width:10px;
  height:10px;

}
.download-section{
  display: flex;
  flex-direction: row;
  gap:5px !important;

}
.download-report-div{
color:#333333;
font-family: "Hyundai Sans Head Office regular" ;
font-weight: 400;
font-size: 15px;
cursor: pointer;

}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(0.1px); /* Apply blur effect */
  z-index: 999; /* Ensure the backdrop is above other content */
}

/* mobile tab css options */


/* device status filter css */

.hyundai-content-section{

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap:20px;
  margin-top: 30px;
}
.device-status-full-contain-section{
  padding-left: 40px;
  padding-right: 40px;

}
.device-status-full-contain-sections{
  padding-left: 20px;
  padding-right: 20px;

}
/* mobile filter device status ui */
.mobile-date-filter-btn{
  display: flex;
  flex-direction: row;
  gap:10%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}
.date-range-mobile{
  flex-basis: 60%;

  /* border:1px solid blue; */
}
.filter-section{
  flex-basis: 30%;
 
  /* border:1px solid blue; */
}
.filters-div{
  /* width: 100%; */
   height:40px;
   align-items: center !important;
   text-align: center !important;
   justify-content: center !important;
   align-self: center !important;
   align-content: center !important;
   border:1px solid #DADADA;
   border-radius: 5px;
   background-color:#FFFFFF ;
   display: flex;
   flex-direction: row;
   gap:10px;
   margin-top: 10px;
   margin-left: 10px;
margin-right: 10px !important;
  

}

/* cards css */
.card-containers {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* max-width: 600px; Adjust the width as needed */
  /* margin: 30px 40px; */
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;

}

.top-five-usage-cards-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* max-width: 600px; Adjust the width as needed */
  /* margin: 30px 40px; */
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  padding-top: 0px;

}
.top-five-usage-cards-containers{
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;

}
.card-containerhome {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* max-width: 600px; Adjust the width as needed */
  margin: 30px 10px;
}
.cardshome {
  /* adjust flex-basis and margin for the cards for gap between cards */
  flex-basis: calc(33.33% - 20px); /* Adjust the width and margin as needed */
  margin: 5px 10px 5px 10px;
  border: 1px solid #FDFCFF;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  cursor: pointer;
}


.cards {
  /* adjust flex-basis and margin for the cards for gap between cards */
  flex-basis: calc(33.33% - 20px) !important;
  margin: 5px 10px 5px 10px;
  border: 1px solid #FDFCFF;
  padding: 20px !important;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* border-radius: 0 !important; */
  /* box-sizing: border-box; */
}
.top-five-flexbox-cards{
  flex-basis: calc(33.33% - 10px) !important;
  margin: 10px 5px 10px 5px;
  border: 1px solid #FDFCFF;
  padding: 20px !important;
  background-color: #FfFfFF;
  border-radius: 10px;


}
.top-five-flexbox-cardsect{
  flex-basis: calc(50% - 10px) !important;
  margin: 10px 5px 10px 5px;
  border: 1px solid #FDFCFF;
  padding: 20px !important;
  background-color: #FfFfFF;
  border-radius: 10px;


}




/* For mobile */


.no-of-div-card{
  display: flex;
  flex-direction: column;
}
.active-inactibe-div{
  display: flex;
  flex-direction: row;
  gap:10%;
}
.active-div{
  flex-basis: 45%;
  padding-top: 20px;
}
.inactive-div{
  flex-basis: 45%;
  padding-top: 20px;

  text-align: center;
}

.active-divs{
  flex-basis: 60%;
  padding-top: 10px;
  text-align: left;

  



}
.inactive-divs{
  flex-basis: 30%;
  /* padding-top: 10px; */
  color:#1A1A1A;
  text-align: right;
  font-weight: 400;
  font-family: "Hyundai Sans Head Office regular" ;



}
.inactive-divstwoo{
  flex-basis: 30%;
  /* padding-top: 10px; */
  color:#1A1A1A;
  font-weight: 400;
  font-family: "Hyundai Sans Head Office regular" ;





}


.no-of-device-img-section {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #D398E7;
  background-image: url('../Assets/chart.png');
  background-size: 20px; /* Ensure the background image covers the entire container */
  background-position: center;
  background-repeat: no-repeat; /* Center the background image */
}

.inactive-img-section{
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color:#E89271;
  background-image: url('../Assets/chart.png');
  background-size: 20px; /* Ensure the background image covers the entire container */
  background-position: center;
  background-repeat: no-repeat;

}



.active-img-section{
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color:#70A1E5;
  background-image: url('../Assets/chart.png');
  background-size: 20px; /* Ensure the background image covers the entire container */
  background-position: center;
  background-repeat: no-repeat;

}


.zone-div{
  color:#2a2a2a;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 800;
  font-size: 15px;
  text-align: center;
}

.device-heading-div{
  color:#9A9A9A;
  font-weight: 400;
  font-size: 16px;
 padding-top: 10px;
}

.device-count-div{
  color:#1A1A1A;
  font-weight: 700;
  font-size: 25px;
  padding-top: 10px;

}

.no-of-device-img-sect {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #D398E7;
  background-image: url('../Assets/chart.png');
  background-size: 20px; /* Ensure the background image covers the entire container */
  background-position: center;
  background-repeat: no-repeat; /* Center the background image */
}
.content-img-sect{

  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #98E79B;
  background-image: url('../Assets/Frame\ \(1\).png');
  background-size: 20px; /* Ensure the background image covers the entire container */
  background-position: center;
  background-repeat: no-repeat;

}
.appupdate-img-sect{
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #E7CC98;
  background-image: url('../Assets/Frame\ \(1\).png');
  background-size: 20px; /* Ensure the background image covers the entire container */
  background-position: center;
  background-repeat: no-repeat;


}
.centralized-num-of-devices-div{
  color:#9A9A9A;
  font-weight: 400;
  font-size: 16px;
  padding-top: 10px;
}
.centralized-devices-count-div{
  color:   #1A1A1A;
  font-weight: 700;
  font-size: 25px;
  padding-top: 10px;

}
.active-content-div{
  color:#9A9A9A;
  font-weight: 400;
  font-family: "Hyundai Sans Head Office regular" ;
  

}
.active-count-div{
  color: #003984;
  /* border-bottom:1px solid blue; */
  /* border-bottom: 3px solid #003984; */
  width:30%;
  justify-content: center;
  cursor:pointer;
  
  
  margin-left: 35%;

  font-weight: 700;
  font-size: 25px;
  padding-top: 10px;
}
.active-count-divss{  
  color: #003984;
  /* border-bottom: 1px solid #003984; */
width:32%;
cursor:pointer;

  font-weight: 400;
  font-size: 16px;
  padding-top: 10px;

}

.active-count-divtwo{
  color:#1A1A1A;
  font-weight: 700;
  font-size: 25px;
  padding-top: 10px;

}
.active-count-divs{
  color:#9A9A9A;
  font-weight: 300;
  font-size: 15px;
  padding-top: 10px;
  align-items: flex-end !important;
  justify-content: flex-end !important;
  align-content: flex-end !important;

}

.cont-app-update-status{
color:  #242424;
padding-top: 10px;
font-weight: 400;
font-size: 16px;


}





/* start device staatus report css */

.hyundai-content-section-statusreport{

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:20px;
  margin-top: 30px;

}
.device-status-full-contain-section-statusreport{
  padding-left: 40px;
  padding-right: 40px;

}

.zone-div-report{
  /* color:  #808080; */
  color:#2a2a2a;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 800;
  font-size: 15px;
  text-align: center;
}

/* search section css */
.search-section-statusreport{
 
  /* padding-top: 40px; */
  /* border:1px solid yellow; */
  flex-basis: 80%;
}

.search-text-div-statusreport{
  padding-bottom: 10px;
  color:#808080;
  font-weight: 400;
  font-size: 16px;

}
.custom-search-icon-staatusreport{
  position: absolute;
  padding-top: 10px;
  padding-left: 10px;
}

.searchbar-statusreport{
  

  color:   #9B9B9B; 
 

 padding: 10px;
 padding-left: 35px;
 border:none;
 outline:none;
 border:1px solid #68718229;
 border-radius: 8px;
 /* box-shadow: 1px 1px 1px 1px #68718229; */
 /* box-shadow: 1px 1px 1px 1px #68718229; */
 height: 40px;
 width:100%;
 font-size:16px ;
 font-family: "Hyundai Sans Head Office regular" ;

}

.searchbar-statusreports{
  

  color:   #9B9B9B; 
 

 padding: 10px;
 padding-left: 35px;
 border:none;
 outline:none;
 border:1px solid #68718229;
 border-radius: 8px;
 /* box-shadow: 1px 1px 1px 1px #68718229; */
 /* box-shadow: 1px 1px 1px 1px #68718229; */
 height: 40px;
 width:50%;
 font-size:16px ;
 font-family: "Hyundai Sans Head Office regular" ;

}
.searchtextwos{
  height: 40px;
  width:10%;
  border:none;
  outline:none;
  background-color: grey;
  border-radius: 10px;

}
.search-text-div-staatusreport{
  color: #808080;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  padding-bottom: 10px;

}

.searchbar-statusreport::placeholder{
  color: #827D7D;
}

.dealercode-table-section{
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 20px;
}
.usagereport-table-section{
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 20px;
}



/* Remove hover effect on table rows */
.ant-table-tbody > tr:hover > td {
  background-color: inherit !important; /* or specify the original background color */
}

/* If you want to remove hover effect only on odd or even rows */
.ant-table-tbody > tr:nth-child(odd):hover > td,
.ant-table-tbody > tr:nth-child(even):hover > td {
  background-color: inherit !important; /* or specify the original background color */
}

.ant-table-tbody > tr:nth-child(odd){
  background-color:  #FAFAFA;
 
}

.ant-table-tbody > tr:nth-child(even){
  background-color:  #ffffff ;
}

/* Change the color of table headings */
.ant-table-thead th {
  color:   #9A9A9A !important;
  font-weight: 400;
  font-size: 14px;
  font-family: "Hyundai Sans Head Office regular" ;

}
/* Change the color of table cell text */
.ant-table-cell {
  /* color:red !important; */
  font-weight: 400;
  font-size:15px ;
  font-family: "Hyundai Sans Head Office regular" ;

}


/* .css-176k2ft-MuiFormControl-root{
  height:40px !important;
} */
.css-176k2ft-MuiFormControl-root {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  position: relative !important;
  min-width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  vertical-align: top !important;
  margin: 0px !important; 
 /* max-height: 30px !important;  */
 height:40px !important;
}
.css-141hkij-MuiFormControl-root {
  display: -webkit-inline-box !important; 
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  position: relative !important;
  min-width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  vertical-align: top !important;
  margin: 0px !important;
  width: 130px !important;
  height:40px !important;
  /* max-height: 30px !important;  */
  /* height: 100px; */
}



.css-ukrbuh {
  display: inline-flex !important;
  flex-direction: column !important;
  position: relative !important;
  min-width: 0px !important;
  padding: 0px !important;
  border: 0px !important;
  vertical-align: top !important;
  margin: 0px !important;
  width: 130px !important;
  height: 40px !important;
}


.change-filters-div{

  
}
.change-filter-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom:10px ;
  /* border:1px solid red; */
  position: fixed;
  height:60px;
  z-index: 100;
 
  /* box-sizing: border-box; */
  width: calc(100vw - 40px);
  /* width:90%; */
  background-color: #FFFFFF;
  border-bottom: 1px solid grey;

}
.accordian-container{

  border:1px solid   #DADADA ;
  box-shadow: none !important;

  border-radius: 10px !important;
  margin-top: 10px !important;
 
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;


}
 .accordian-container.expanded {
  margin-left: 10px; /* Reset left margin when expanded */
    margin-right: 10px;  
   }  
.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded{
  margin:10px !important;
}
.accordian-summary {
  transition: border-bottom 0.3s;


}

.accordian-summary.expanded {
  border-bottom: 1px solid   #DADADA !important;

}


/* for modal width to be 100% */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 10px !important;

}
/* for according before line is displaying */
.css-1086bdv-MuiPaper-root-MuiAccordion-root::before{
  opacity:0 !important;

}
.mui-filter-modaal{
  margin-bottom: 150px !important;
  margin-top: 150px !important;

  /* border:1px solid grey !important; */
  /* border-top: none !important;
  border-left: none !important;
  border-right: none !important; */
}
.mui-filter-modaals{
  margin-bottom: 20px !important;
  margin-top: 60px !important;

}
/* to make tab option reduce sspace in left and right
 */


 /* ---------usage report css ------------------ */

 .device-status-full-contain-section-usage-report{
  padding-left: 40px;
  padding-right: 40px;
}

.hyundai-content-section-usage-report{

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:20px;
  margin-top: 30px;
}
.zone-div-usage-report{
  color:  #808080;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  font-size: 15px;
}

.search-section-usagereport{
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
}



/* media queirs for navbar */


@media (max-width: 480px) {
  .cards {
      flex-basis: calc(100% - 20px) !important; 
    /* margin: 10px 0; Adjust the margin for mobile */
  }
  .cardshome {
    flex-basis: calc(100% - 20px) !important; /* Show 2 cards in a row */
} 
 
  .top-five-flexbox-cards{
    flex-basis: calc(100% - 10px) !important; 

  }
  .card-containers{
    padding-left: 5px;
    padding-right: 5px;
  }
  .top-five-usage-cards-container{
    padding-left: 5px;
    padding-right: 5px;
  }
  .status-tab {
    padding-left: 10px !important;
    padding-right: 10px !important;

  }
  .mobile-date-filter-btn{
    
    padding-left: 10px;
    padding-right: 10px;
    
  }

  .top-five-flexbox-cardsect{
    flex-basis: calc(100% - 10px) !important;
    margin: 10px 5px 10px 5px;
    border: 1px solid #FDFCFF;
    padding: 20px !important;
    background-color: #FfFfFF;
    border-radius: 10px;
  
  
  }
  
}


/* ---media queries  */
@media (max-width:769px){



  .side-arrows{
    display: none;
  }

  
  .active-count-divss{ 
    width:100%; 

  }
  .fetch-details-section{
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-top: 10px;
    border:1px solid #DADADA;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding:20px;
  }

  .cardshome {
    flex-basis: calc(100% - 20px) !important; /* Show 2 cards in a row */
}
 
  
  .device-status-full-contain-section{
    padding-left: 10px;
    padding-right: 10px;
  
  }
  .device-status-full-contain-sections{
    padding-left: 10px;
    padding-right: 10px;
  
  }
  .device-status-full-contain-section-statusreport{
    padding-left: 10px;
    padding-right: 10px;
  
  }
  .device-status-full-contain-section-usage-dashboard{
    padding-left: 10px;
    padding-right: 10px;

  }
  .device-status-full-contain-section-usage-report{
    padding-left: 10px;
    padding-right: 10px;

  }
  
  .row-device-sec{
    cursor:none !important;
  }
  .row-device-sec-new{
    cursor:none !important;
  }


  .popup {

    width:80% !important;
  
    /* border: 1px solid black; */
  
  }
  .dealer-details-individual-cards{
 
    flex-basis: calc(100% - 5px) !important;
     margin: 5px 10px 5px 10px;
     /* border:1px solid blue !important; */
   }
  

  .nav-links{
    display:none ;
  }
  .mobile-menu-icon{
    display: block;
  position: absolute;  
        /* left:250px;  */
        right:20px;
        cursor:pointer; 
  }
  .nav-links-mobile{
    /* dont use flex here its not working */
     display:block; 
    top:40px;
    position:absolute;
    background-color:#ffffff;
    width:100%;
    transition:0.5s ease ease-in-out;
  
    text-decoration: none;
    list-style-type: none;
    height:auto;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
   padding-bottom: 20px !important;
   /* border-bottom: 2px solid grey ; */
  }
  .welcome-text{
    padding-bottom: 10px;

  }
  .logout-btn {
    border: 1px solid #685290;
    border-radius: 5px;
  /* Use inline-flex to allow the button to take the height of its content */
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    width: auto; 
    cursor:none;
  
  }
  .navbars{
    display:flex;
    /* to go to end....push both the items to both edges*/
  
    background-color:  #FFFFFF;
   
    /* to keep all items in middle of navbar */
  
    border-bottom: 1px solid #B8BAB6;
  
    padding-left: 0px;
    z-index: 10000 !important;
    
  
  }





  .change-text-div{
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }


  .card-containers {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* max-width: 600px; Adjust the width as needed */
    /* margin: 30px 40px; */
    padding-top: 20px;
   padding-bottom: 20px !important;
   padding-left: 5px;
   padding-right: 5px;



  }
  .top-five-usage-cards-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* max-width: 600px; Adjust the width as needed */
    /* margin: 30px 40px; */
    padding-top: 0px;
   padding-bottom: 20px !important;
   padding-left: 10px;
   padding-right: 10px;

  }

  .cards {
    flex-basis: calc(100% - 20px) !important; 
    
  }
  .top-five-flexbox-cards{
    flex-basis: calc(100% - 10px) !important; 

  }
  .status-tab {
    padding-left: 10px !important;
    padding-right: 10px !important;

  }
  .mobile-date-filter-btn{
 
    padding-left: 10px;
    padding-right: 10px;
  
  }

  .searchbar-statusreport{
    width:100%;
  }
  .searchbar-statusreports{
    width:100%;
  }
  .search-section-statusreport{
    /* padding-left: 10px;
    padding-right: 10px; */
    /* padding-top: 20px; */
  }
  .search-section-usagereport{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 40px;
  }
  .dealercode-table-section{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .usagereport-table-section{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .prev-btn{
    cursor: none;
  }
  .next-btn{
    cursor: none;
  }

  .usagedashboard-line-graph{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  

  /* medi aquery for pie */
  .piegraaph-specifications-flexbox-contaainer{
    flex-direction: column !important;

  }
  .piegraph-flexbox-container{
    display:flex;
    flex-direction: column !important;
    gap:10px;
  }
  .usagedashboard-pie-graph-section {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .top-five-usage-cards-section {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom:10px !important;
  }
.details-page-navbar{
  display: flex;
  flex-direction: column !important;
  gap:20px !important;
  /* justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  text-align: flex-start; */
  /* padding-top: 80px !important; */
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

}
.dealers-detail-fullbox-flex-container{ 
   border:1px solid #FFFFFF;
padding-top: 5px;
padding-bottom: 5px;
/* padding-bottom: 20px; */
margin-top: 25px;
/* margin-bottom: 20px; */
padding-left: 5px;
padding-right: 5px;
background-color:#FFFFFF;
margin-left: 10px !important;
margin-right: 10px !important;
}
.seaarch-report-flexbox-container{
  display: flex;
  flex-direction: row;
  /* border:1px solid green; */
  justify-content: space-between;
  padding-left: 10px !important;
  padding-right: 10px !important;
  align-self: center;
  align-items: center;
  align-content: center;
}
 .download-report-mobile-container{
  visibility: hidden !important;
 }



 

.ant-picker-panel-container{
  margin-left: 5% !important;
  margin-right: 5% !important;
  width: 90%;
  }

.ant-picker-panels{
  display: flex;
  flex-direction: column;
}








.actions-text{
  color:#9F9F9F;
  font-size: 13px !important;
  font-family: "Sora", sans-serif !important;
  


}
.new-selections-text{
  color:  #242424;

  font-size: 15px !important;
  font-family: "Sora", sans-serif !important;

}
.device-status-full-contain-section-usage-dashboard{
  padding-left: 10px;
  padding-right: 10px;
}
.fetch-details{
  border:1px solid #685290;
  color:white;
 padding: 10px !important;
 background-color: #685290;
 border-radius: 8px;
 cursor:pointer;

}


  /* to remove line in accordian */
  /* .css-1aj41gs::before {
    background-color:#FFFFFF !important;
}
   */
/* 
   .css-1aj41gs::before{

      opacity:0 !important

   } */

.css-1aj41gs::before{
  opacity:0 !important;
}


/* for popun to reduce padding in  detail page */
.css-ypiqx9-MuiDialogContent-root {
  padding:0px 0px !important;

}


.Totusagenum-flexbox-container{
  display: flex;
  flex-direction: column !important;
  gap:20px !important;
 
  align-items: center;
  align-content: center;
}


/* .css-1aj41gs.Mui-expanded {
  margin: 0px 0px !important;
} */


}





/* --------------usage dashboard css-------------------------- */
.device-status-full-contain-section-usage-dashboard{
  padding-left: 40px;
  padding-right: 40px;
}
.hyundai-content-section-usage-dashboard{

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap:20px;
  margin-top: 30px;
}
.zone-div-usage-dashboard{
  color:  #808080;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  font-size: 15px;
}

.usagedashboard-line-graph{
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.linegraph-heading-flexboxcontainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.bargraph-heading-flexboxcontainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;


}


.prev-next-flexbox-container{
  display: flex;
  flex-direction: row;
  gap:10px;
}
.prev-btn{
  cursor: pointer;
}
.next-btn{
  cursor: pointer;
}

.usagedashboard-pie-graph-section{
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;

}
.piegraph-flexbox-container{
  display:flex;
  flex-direction: row;
  gap:10px;
}
.pie-graph-container{
  height: 100%;
  padding: 20px;
   border: 1px solid #FFFFFF; 
   border-radius: 4px;
    background-color: white; 
    flex-basis: 75%;


}

.overall-usage-num-container{
  height: 100%;
  padding: 20px;
   border: 1px solid #FFFFFF; 
   border-radius: 4px;
    background-color: white ;
    flex-basis: 24%;
    display: flex;
    flex-direction: column;
   

}

.ads-img-section {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color:   #E89271;

  background-image: url('../Assets/ads_click.png');
  background-size: 20px; /* Ensure the background image covers the entire container */
  background-position: center;
  background-repeat: no-repeat; /* Center the background image */
}
.overall-usaage-num-div{
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 700;
  color:  #1A1A1A;
  font-size: 20px;
  padding-top: 15px;


}
.overall-usaage-text-div{
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  font-size: 16px;
  color:  #7E7E7E;
  padding-top: 5px;
}
.statistics-text-div{
  color:#9291A5;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  font-size: 14px;


}
.usagebysection-text-div{
  color:  #1E1B39;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 700;
  font-size: 18px;
}
.pie-statics-heading-container{
  border-bottom: 1px solid #E5E5EF;
padding-bottom: 20px;
}
.piegraaph-specifications-flexbox-contaainer{
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  gap:10px;
}
.piegraph-section{
  flex-basis: 50%;
  /* border:1px solid blue; */
  padding: 20px;
}
.piegraph-data-detail-section{
  flex-basis: 50%;
  /* border:1px solid blue; */
  /* padding: 20px; */

}
.piegraph-details{
  display:flex;
  flex-direction: column;
  gap:5px;
  /* border: 1px solid yellow; */

 justify-content: center; 
  align-items: center; 

  /* margin-top: 10%; */
  min-height: 100%;
  



}
.bubble-data-detail-flexboxcontainer{
  display: flex;
  flex-direction: row;
  gap:5px;
 
}

/* cards containers */
.top-five-usage-cards-section{
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
}
.top-five-card-option{
  display: flex;
  flex-wrap: row;
  justify-content: space-between;
  /* border-bottom: 1px solid grey; */
  padding-top: 10px;
  padding-bottom: 10px;

}
.top-five-card-option:not(:last-child) {
  border-bottom: 1px solid #E5E5EF;
}
.topfivecards-text-heading{
  border-bottom: 1px solid #E5E5EF;
  padding-bottom: 10px;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 700;
  color:#1E1B39;
}

.top-card-list-options{
  padding-top: 10px;
}
.top-five-usage-cards-title{

  color:#4D4D4D;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  font-size: 15px;
}
.top-five-usage-cards-price{
  color:#32C000;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 600;
  font-size: 15px;
  }
  .top-five-usage-cards-prices{
    color:#D10000;
    font-family: "Hyundai Sans Head Office regular" ;
    font-weight: 600;
    font-size: 15px;
  }



  /* dealer's detail page css */

.details-page-navbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  /* align-content: center; */
  /* text-align: center; */
  padding-top: 10px !important;
  padding-left: 10px;
  padding-right: 40px;

}
.details-deaalers-paage-full-container{
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 100px;
}
.dealer-detail-downloadreport-container{
  display: flex;
  flex-direction: row;
  gap:5px;
}
.back-date-flexbox-container{
  display: flex;
  flex-direction: row;
  gap:40px;
}
.details-backicon-flexbox-container{
  display: flex;
  flex-direction: row;
  gap:5px;
 justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center; 
 
}
.dealers-detail-fullbox-flex-container{
  border:1px solid #FFFFFF;
  padding-top: 5px;
  padding-bottom: 5px;
  /* padding-bottom: 20px; */
  margin-top: 25px;
  /* margin-bottom: 20px; */
  padding-left: 5px;
  padding-right: 5px;
  background-color:#FFFFFF;
  margin-left: 5px;
  margin-right: 5px;
}

.dealers-detail-navbar-heading-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #E6E6E6;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dealers-details-image{
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #E89271;
  background-image: url('../Assets/chart.png');
  background-size: 20px; /* Ensure the background image covers the entire container */
  background-position: center;
  background-repeat: no-repeat; /* Center the background image */
}
.dealers-details-text-image-container{
  display: flex;
  flex-direction: row;
  gap:5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
}
.dealer-detail-firstrow-data-container{
  display:flex;
  flex-direction: row;
  gap:10px;

  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  /* padding-top: 20px; */


}
.dealer-details-individual-cards{
 
 flex-basis: calc(23% - 20px);
  margin: 5px 10px 5px 10px;
  /* border:1px solid red; */
}

.dealer-detail-secondrow-data-container{

  margin-top: 20px;
  display:flex;
  flex-direction: row;
  justify-content: space-between;


}
.Dealer-details-id{
  font-family: "Sora", sans-serif !important;
  font-size: 12px;
  color:#9A9A9A;
  font-weight:400;


}
.Dealers-detail-number{
  font-family: "Sora", sans-serif !important;
  font-size: 16px;
  color:#1A1A1A;
  font-weight:600;
  /* padding-top: 4px; */
}
.dealers-detaails-activity-status{
  padding-top: 20px;
}
.dealers-details-content-app-update-section{
  padding-top: 20px;

}
.dealers-details-tab-flexbox-container{
  display: flex;
  flex-direction: row;
  gap:40px;
}
.dealers-details-tab-content{
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  padding-top: 20px;
  /* margin-left: 40px; */
  /* margin-right: 40px; */
  background-color:#FFFFFF;
}
.dealers-detaails-contentname-full-container-table{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:5px;
  /* border-bottom: 1px solid #CCCCCC; */

}
.dealers-details-content-app-flexbox-cards{
  /* flex-basis: calc(33.33% - 20px) !important; */
  /* margin: 5px 10px 5px 10px; */
  /* border: 1px solid #E6E6E6; */
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  /* background-color: #FDFCFF; */
  border-radius: 10px;
  margin-bottom: 20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */

}
.dealers-content-name-heading-text{

  padding-bottom: 10px;

  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  color:#9A9A9A
}
.content-app-cards-options-container{
  padding-top: 10px;
}

.content-app-cards-options{
  display: flex;
  flex-wrap: row;
  justify-content: space-between;
  /* border-bottom: 1px solid grey; */
  padding-top: 10px;
  padding-bottom: 10px;

}
.content-app-cards-options:not(:last-child) {
  border-bottom: 1px solid #E5E5EF;
}


.dealers-details-content-app-cards-title{

  color:#4D4D4D;
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  font-size: 18px;
}
.dealers-details-content-app-cards-price{
  font-family: "Hyundai Sans Head Office regular" ;
  font-weight: 400;
  font-size: 16px;
  }


  .content-app-cards-title{

    color:#1A1A1A;
    font-family: "Hyundai Sans Head Office regular" ;
    font-weight: 400;
    font-size: 18px;
  }
  .content-app-cards-price{
    font-family: "Hyundai Sans Head Office regular" ;
    font-weight: 400;
    font-size: 16px;
    color:#1A1A1A;
    }

    /* login css */
    
   section {
    width: 100%;
   

    position: fixed;
    background-color: #FFFFFF;
  
    /* position: relative; */
    height: 100vh;
    /* background-image: url('../Assets/rm222batch5-kul-artboard-2023-11-27-05-12-47-utc.jpg'); */
    /* Adjust background properties as needed */
    background-size: cover; /* Adjusts the size of the background image to cover the entire container */
    background-position: center;
    /* height:auto; */
    /* background: linear-gradient(to right, #61a4e2e8, #e982a3) !important; */

    /* #0084ffc5 Adjust color codes as needed */
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
     {
       display: none;
     } 

.form-container{
    position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #FCFCFC;
   
    /* background-image: url('Vector.png') ; */
    border:1px solid #DBDBDB;
    border-radius:20px;
    width:390px;
    height:400px;
    padding:20px;
    justify-content: center;
    align-items: center;
    align-content: center;

    display: block;
   
}
.login-Hyundai-image{
  text-align: center;
}
label{
    font-size:14px;
 }

 .Email-icon{
    position:absolute;
     top: 50%;
     left:12px; 
     transform:translateY(-50%);
          width:28px;
     height:28px;
     }
.password-icon{
    position:absolute;
     top: 50%;
     left:12px; 
     transform:translateY(-50%);
     width:28px;
     height:28px;
    } 
    .password-iconvisibility{
      position:absolute;
       top: 50%;
      right:12px; 
       transform:translateY(-50%);
       width:18px;
       height:18px;
       object-fit: contain;
      } 
   

 .control input{
    width:100%;
    display: block; 
   padding:15px 15px 15px 52px; 
    border-radius:8px;
     background-color:#FFFFFF;
 
   
     /* #F8F8F8; */
    outline:none;
    border:none;
    border:1px solid  grey !important;
    margin:8px 0 0 0;   
  }
  .control{
      position:relative;
      width:100%;
      
  }
  
 .controls{
  color:#000000;
  margin-top:10px ;


  font-size: 14px;
 }

/* YourComponent.css */

input::placeholder {
    color: #B7B7B7 ; 
    /* Change the color to your desired value */
  }
  
 /* ::placeholder {
    color:#B7B7B7;
  } */
  .forgot-password{
        
    color:#7E7E7E;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    padding-top: 10px;
 }

.div-forgot-password{
    text-align: right;
    margin-right: 5px;
    margin-top: 5px;
 }

.images{
    display:block;  
       width:25%; 
       align-items: center; 
       margin:0 auto 20px auto; 
 }
 .login-form-controller{
  margin-top: 40px;
 }
 
.control{
    position:relative;
    width:100%;
    margin-top: 20px;
  
    }
    .form-input{
        border:2px solid grey;
     outline:none;
        background-color: #FFFFFF;
       width:100%;
        padding:10px;
        margin-top: 10px;
    }
     .textone{
        position:absolute;
     top:20px;
     left:1.4rem;
        cursor:text;
        transition: top .3s ease-in-out;
        background:#FFFFFF;
     
     
       
        /* background-image: url('Vector.png') ; */
      
     }
    
    
    .form-input:focus ~ .textone ,.form-input:not(:placeholder-shown).form-input:not(:focus) ~ .textone{
        top: 0.20rem;
       left:0.5rem;
        font-size:12px ;
         }

     .link1{
        text-align: right;
        color:#979797;
        font-size: 12px;
     }
     .login{
    background-color:  #685290;
    color:white;
    display:block; 
   
       width:100%;   
       margin:45px  auto 20px auto; 
    padding:15px 28px; 
     align-items: center; 
    border: none;
    outline: none;
    border-radius:10px;
    font-size: 12px;
    cursor: pointer;
     }
  .textthree{
    cursor: pointer;
    text-align:center;
        color:#979797;
        font-size: 10px;
    }

.signup{
    background-color:#FFFFFF;
    color:#017ff4e8;
    display:block; 
       width:45%;   
       margin:10px  auto 25px auto; 
       padding:11px 28px; 
     align-items: center; 
     border: none; 
    outline: none;
    border-radius:10px;
     border:1px solid #017ff4e8; 
    font-size: 12px;
}

@media (max-width:769px){
  .piegraph-details{
    justify-content: flex-end;
     align-items: start !important;

  }
    .form-container{
        width:80%;
       
    }
    .control input{
        width:100%;
        display: block; 
       padding:15px 15px 15px 52px; 
        border-radius:8px;
         background-color: #f8f8f8;
        
         /* #F8F8F8; */
        outline:none;
        border:none;
        margin:8px 0 0 0;   
      }
      .forgot-password{
        
        color:#979797;
        font-size: 12px;
        text-decoration: none;
        cursor: none;
     }
     .login{
      background-color:  #685290;
      color:white;
      display:block; 
         width:50%;   
         margin:30px  auto 20px auto; 
      padding:12px 28px; 
       align-items: center; 
      border: none;
      outline: none;
      border-radius:10px;
      font-size: 12px;
       }
    
}
/* ---end of login css----- */
  
.download-device-section{
  justify-content: flex-end !important;
  align-items: flex-end !important;
  align-content: flex-end !important;

}
.seaarch-report-flexbox-container{
  display: flex;
  flex-direction: row;
  /* border:1px solid green; */
  justify-content: space-between;
  gap:10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  
}
.download-device-sections{
  /* padding-top: 30px; */
 border:1px solid #68718229;
 
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-top: 34px;
  height:40px;
  padding: 10px;
  text-align: center;
  align-content: center;
  width:18%;

}
.download-status-reporticon{
  width:3px;
  height:20px;


}
.download-device-section{
 /* margin-top: 80px; */
 /* margin-top: 50px;  */
  /* border:1px solid blue; */
  align-items: flex-end;
  flex-basis: 19%;
  
}
/* .download-sections{
  display: flex;
  flex-direction: row;
  /* gap:2px; */
/* } */ 
.download-report-mobile-container{
  text-align: right;
  justify-content: right;
  padding-right: 40px;
  padding-top: 20px;
  /* border:1px solid blue; */
  
}
.download-report-divs{
  display: flex;
  flex-direction: row;
  text-align: right;
    gap:2px;
    justify-content: flex-end;
    align-items:bottom;
    /* align-content: flex-end; */
}


.navbars{

  z-index: 10000 !important;
  

}
.downloadfile-icon{
  cursor: pointer;
}

/* FOT OPTIONS IN MBILE DROP DOWN */



/* ----END OF DROPDOEN CSS----- */


/* reset passowrd screen */
.popup {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width:390px;

  /* border: 1px solid black; */
  padding: 20px;
 
  box-shadow: 2px 2px 2px 2px #EEEEEE;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.close-device-status-btn{
  border:none;
  outline:none;
  border: 1px solid grey;
  padding:10px;
  margin-left: auto;
  color:red;


}
.close-btn{
  border:none;
  outline:none;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  cursor:pointer;
}



/* ---compmets fech----- */
.fetch-details-section{
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
  border:1px solid #DADADA;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding:20px;
}
.parent-action-class{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:5px;
  /* justify-content: center; */
  align-items: center;
  align-content: center;
}
.actions-text{
  color:#9F9F9F;
  font-size: 16px;
  font-family: "Sora", sans-serif !important;
  


}
.new-selections-text{
  color:  #242424;

  font-size: 20px;
  font-family: "Sora", sans-serif !important;

}
.fetch-details{
  border:1px solid #685290;
  color:white;
 padding: 15px;
 background-color: #685290;
 border-radius: 8px;
 cursor:pointer;

}







/* -------dealer code----------- */
.dealer-sect{
  
  font-size: 15px;
  font-family: "Sora", sans-serif !important;
   color:#FFFFFF !important;
   font-weight: 400 ;


}
.row-device-sec{
  color:#1A1A1A ;
  font-family: "Sora", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  cursor:pointer;

}
.row-device-sec-new{

  font-family: "Sora", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
   cursor:pointer !important;

}
.pagination-div-status-report{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  align-content: center;
}




.circle {
  width: 10px; /* Adjust the width and height to change the size of the circle */
  height: 10px;
  border-radius: 50%; /* This creates a circular shape */
  display: inline-block;
  margin-left: 5px; /* Adjust the margin to create space between the circle and the text */
}

.paginattion-flex-device-status-report{
  display: flex;
  flex-direction: row;
  gap:5px;
  justify-content: flex-end;
  margin-top: 10px;
}


.contentname-title-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px !important;
  font-family: "Sora", sans-serif !important;
  font-weight: 400;
  color:#9A9A9A;
  font-size: 15px;
 
}
.back-btn-delaers{
  cursor:pointer;
}





/* for Line Graph */
.prev-btn-img{
  width:100%;
   height:100%;
  object-fit: contain;
  text-align: center;
  align-items: center;
  align-content: center;
   justify-content: center;

}
.next-btn-img{
  width:100%;
  height:100%;
  object-fit: contain;
  text-align: center;
  align-items: center;
  align-content: center;
   justify-content: center;
}




.prev-btn {
  background-color: #F5F5F5;
  width:35px;
  height:35px;
  padding: 10px;
  border-radius: 10px;
  cursor:pointer; /* Set initial background color to yellow */
}

.prev-btn.clicked {
  background-color: #DEDEDE; /* Change background color when clicked */
}

.next-btn {
  background-color: #F5F5F5;
  padding: 10px;
  width:35px;
  height:35px;
  border-radius: 10px;
  cursor:pointer;
}


.next-btn.clicked {
   background-color: #DEDEDE;/* Change background color when clicked */
}



/* for pie graph  */



.prev-btnTwo {
  background-color: #F5F5F5;
  width:35px;
  height:35px;
  padding: 10px;
  border-radius: 10px; 
  cursor:pointer;/* Set initial background color to yellow */
}

.prev-btnTwo.clicked {
  background-color: #DEDEDE; /* Change background color when clicked */
}

.next-btnTwo {
  background-color: #F5F5F5;
  padding: 10px;
  width:35px;
  height:35px;
  border-radius: 10px;
  cursor:pointer;
}


.next-btnTwo.clicked {
   background-color: #DEDEDE;/* Change background color when clicked */
}
  
/* Add this CSS to your stylesheet */
.dropdown-menu {
  max-height: 200px !important; /* Adjust this value as needed */
  overflow-y: auto !important;
}




.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #ffffff !important;
}


  
/* :where(.css-dev-only-do-not-override-dkbvqv).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {

  display: inline-flex !important;
  flex-direction: column !important;
} */


.selected-device-type {
  background-color: blue !important; /* Change this color to the desired shade of blue */

}



/* to remove line in accordian */

/* .css-1aj41gs::before {
  background-color:#FFFFFF !important;

} */

/* 

/* below is to remove line */



.css-1aj41gs::before{
  opacity:0 !important;
}

/* .css-1aj41gs.Mui-expanded {
  margin: 0px 0px !important;
} */


 .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  max-width: 100% !important;
  max-height:100% !important;
   /* position: absolute !important;
  top:50px !important;
  left:10px !important;
  right:10px !important;
  bottom: 20px !important; */
} 

.css-uhb5lp {
   max-width: 92% !important;
}


.fade-out{
    border:1px solid #E1E1E1;
  background-color:#E1E1E1;
  color:#B4B4B4;
 

}
.fade-in{
  border:1px solid #685290;
  background-color:#685290;
  color:#FFFFFF;
 
 

}


.fetch-detailsbtn{

  outline:none;
  height:40px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  cursor:pointer;
}
.fetch-detailsbtntwo{
  outline:none;
  height:40px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  cursor:pointer;
  color:white;
  background-color: #685290;
  border:none;
  

}
.fetch-detailsbtnmobile{

  outline:none;
  height:40px;
  margin-top: 10px;
 
  border-radius: 5px;
  cursor:pointer;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  align-items: center;
  align-content: center;
  

}
.close-btns{
  text-align: right !important;
  align-items: right !important;
}


.heightprop-check{
  height:auto !important;
  min-height: 20px !important;
}


.css-ypiqx9-MuiDialogContent-root {
  padding:5px 5px !important;

}


/* Hide the scrollbar for the dialog component */
.mui-filter-modaal::-webkit-scrollbar {
  display: none !important;
}


.commingSoonPage{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  color:#000000;
  font-weight: 800;
  font-size: 100px;

}


.hyundai-digilens{
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  padding-left: 18px;
  margin-top: 10px;
}


.bootom-cardss-section{
  border-top: 1px solid grey;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* for mobile dropdown  tab */
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #003984 !important;
  color:#FFFFFF !important;
}


.search-cont{
  display: flex;
  flex-direction: row;
  gap:10px;
}


.searchtextone{
  flex-basis: 50% !important;

}
.searchtextw{
  flex-basis: 40% !important;
}

.offline-details{
  color:#9A9A9A;
}


.Totusagenum-flexbox-container{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  align-content: center;
}

.img-text-container{
  display: flex;
  flex-direction: row;
  gap:10px;
  align-items: center;
  /* justify-content: center; */
  align-content: center;
  flex-basis: 40%;
}


.overallusg-num{
  color:#9291A5;
  font-weight: 500;
  font-size: 20px;

}
.tot-visit{
  color: #1A1A1A;
  font-weight: 700;
  font-size: 25px;
  flex-basis: 60%;



}




.piegraph-details {
  display: flex;
  flex-direction: row !important;
   gap:40px;

}

.column {
  display: flex;
  flex-direction: column;
  gap:20px;
}

.bubble-data-detail-flexboxcontainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.name{
  color:#5C5C64;
  font-weight: 400;

}
.count{
  color:#1E1B39;
  font-weight: 700;

}


@media (max-width: 1000px) {

  .piegraph-details {
    display: flex;
    flex-direction: column !important;
     gap:10px;
     /* justify-content: left !important;
     align-items: left !important; */
  }
  .column {
    display: flex;
    flex-direction: column;
    gap:20px;
    
}
  

  
}


.active-inactibe-divss{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.model-flex-box-cont{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 8px;
  padding-top: 10px;
}

.content-close-section{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCCCCC;
}

.content-naame-flex-cont{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color:#9A9A9A;
}


.custom-range-picker-dropdown .ant-picker-cell {
  cursor: pointer !important;
}

.custom-range-picker-dropdown .ant-picker-header,
.custom-range-picker-dropdown .ant-picker-body,
.custom-range-picker-dropdown .ant-picker-footer {
  cursor: pointer !important;
}


.snackbar{
  z-index: 500000  !important;
}




